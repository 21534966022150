import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { adminURL, esewaID, esewaURL, NodePOSTApi, NodePUTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { CustomButton } from '../../CustomButtom';
import ImageUpload from '../../ImageUpload';
import NotFound from '../../NotFound';

export default function BatchPayment(props) {
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()
  const [reload, setReload] = React.useState(true)
  const [NotFoundData, setNotFound] = React.useState(false)
  const [Data, setData] = React.useState({})
  const [VerificationStatus, setVerificationStatus] = React.useState("Unverified")
  const [VerificationMethod, setVerificationMethod] = React.useState("Esewa")
  const [VerificationID, setVerificationID] = React.useState("")
  const [VerificationDate, setVerificationDate] = React.useState("")
  const [PaidAmount, setPaidAmount] = React.useState("")
  const [AmountToPay, setAmountToPay] = React.useState("")
  const [ExamPrice, setExamPrice] = React.useState("")
  const [item, setItem] = React.useState("")
  const [OrderID, setOrderID] = React.useState("")
  const CustomButton = ({ setData, Data, DataValue, ImageUrl }) => {
    return (
      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
        onClick={() => {
          setData(DataValue)
        }}
      >
        <div className={Data == DataValue ? "active-option" : "passive-option"}>
          {ImageUrl !== "" ?
            <div style={{ width: "100px", height: "30px", }}>
              <img src={ImageUrl} style={{ width: "100px", height: "30px", objectFit: "cover" }} />
            </div>
            :
            <div style={{ width: "100px", height: "30px", }}>
              {DataValue}
            </div>
          }
        </div>
      </button>
    )
  }
  const myform = React.useRef(null)
  function generateRandomNumber() {
    var minm = 1000;
    var maxm = 9999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }
  const [pid, setPid] = React.useState("")

  const fetchBatch = (batchSlug) => {
    setReload(true)
    NodePOSTApi({
      userID: Login.userID,
      batchSlug: batchSlug
    }, "batch-payment-details")
      .then(res => {
        if (res.data.error)
          setNotFound(true)
        else {
          const orderData = res.data.order_data
          setData(res.data)
          setItem(res.data.batch_data[0])
          setExamPrice(res.data.batch_data[0].meta.batch_price)
          if (orderData.length !== 0) {
            setOrderID(orderData[0].id)
            setVerificationStatus(orderData[0].meta.verification_status)
            setVerificationMethod(orderData[0].meta.verification_method)
            setVerificationID(orderData[0].meta.verification_id)
            setVerificationDate(orderData[0].meta.verification_date)
            if (orderData[0].meta.verification_status !== "Unverified")
              setAmountToPay(orderData[0].meta.amount_to_pay)
            else
              setAmountToPay(res.data.batch_data[0].meta.batch_price)
            setPaidAmount(orderData[0].meta.paid_amount)
          }
          else
            setAmountToPay(res.data.batch_data[0].meta.batch_price)
        }
      })
      .finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    fetchBatch(location.pathname.slice(slugs.batch_payment.length + 1))

  }, [])
  const BuyEditSuccess = (res) => {
    if (VerificationMethod == "Manual") {
      window.location.reload()
    }
    else if (VerificationMethod == "Esewa") {
      const RandNum = generateRandomNumber()
      setPid(`${res.data.id}-${RandNum}`)
      setTimeout(() => {
        localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
        localStorage.setItem("AmountPaid", `${ExamPrice}`)
        myform.current.submit()
      }, 100)
    }
    else {
      KhaltiPayment(res.data.id)
    }
  }
  const BuyBatchHandle = (data) => {
    setReload(true)
    NodePOSTApi({ orderData: JSON.stringify(data) }, "buy-batch")
      .then(res => {
        console.log(res.data)
        setOrderID(res.data.id)
        BuyEditSuccess(res)
      }
      ).finally(() => {
        setReload(false)

      })
  }
  const PayBatchHandle = (data) => {
    setReload(true)
    NodePUTApi({
      orderData: JSON.stringify(data),
      id: OrderID
    }, "buy-batch")
      .then(res => {
        console.log(res.data)
        BuyEditSuccess(res)
      }
      ).finally(() => {
        setReload(false)

      })
  }
  const KhaltiPayment = async (orderID) => {
    const RandNum = generateRandomNumber()
    setPid(`${orderID}-${RandNum}`)
    localStorage.setItem("pid", `${orderID}-${RandNum}`)
    localStorage.setItem("AmountPaid", `${ExamPrice}`)

    NodePOSTApi(
      {
        ExamPrice: `${ExamPrice}00`,
        orderID: orderID,
        RandNum: RandNum,
        item: item,
        Login: Login,
        return_url: `${adminURL}batch_payment_khalti/${orderID}${location.pathname.slice(slugs.exam_payment.length + 1)}`
        // return_url: `http://localhost:3000/batch_payment_khalti/${orderID}${location.pathname.slice(slugs.exam_payment.length + 1)}`
      }, "khalti_payment"
    )
      .then(res => {
        if (!res.data.error) {
          window.location.replace(res.data.data.payment_url)
        }
      })
      .catch(err => {

      })
  }
  return (
    <div>
      {!reload ?
        <>
          {NotFoundData ?
            <NotFound /> :
            <div>

              <div className="row">
                <div className="col-sm-2 col-1"></div>
                <div className="col-sm-8 col-10 form-view m-3">
                  <table>
                    <tbody>
                      <tr>
                        <td>Batch Name :</td>
                        <td>{item.meta.batch_name}</td>
                      </tr>
                      <tr>
                        <td>Batch ID : </td>
                        <td>{item.id}</td>
                      </tr>
                      <tr>
                        <td>Student Name : </td>
                        <td>{Login.userName}</td>
                      </tr>
                      <tr>
                        <td>Student ID : </td>
                        <td>{Login.userID}</td>
                      </tr>
                      <tr>
                        <td>Student Email : </td>
                        <td>{Login.userEmail}</td>
                      </tr>
                      <tr>
                        <td>Student Phone Number : </td>
                        <td>{Login.userPhoneNumber}</td>
                      </tr>
                      <tr>
                        <td>Batch Price: </td>
                        <td>Rs. {ExamPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2 col-1"></div>
                <div className="col-sm-8 col-10 form-view m-3">
                  Orders Details:
                  <section>
                    <table>
                      <tbody>
                        <tr>
                          <td>Batch Price : </td>
                          <td>Rs. {AmountToPay}</td>
                        </tr>
                        {PaidAmount !== "" &&
                          <tr>
                            <td>Amount Paid : </td>
                            <td>Rs. {PaidAmount}</td>
                          </tr>
                        }
                        {VerificationDate !== "" &&
                          <tr>
                            <td>Payment Status : </td>
                            <td>{VerificationStatus}</td>
                          </tr>
                        }
                        {VerificationDate !== "" &&
                          <tr>
                            <td>Ordered Date : </td>
                            <td>{VerificationDate}</td>
                          </tr>
                        }
                        <tr>
                          <td className="col-6">Payment Method : </td>
                          <td className="col-6">
                            {VerificationStatus == "Unverified" ?
                              <>
                                {/* <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"} />
                                <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"} /> */}
                                {/* <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} /> */}
                                <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"}
                                  ImageUrl={esewaURL}
                                />
                                {/* <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"}
                                  ImageUrl="https://dao578ztqooau.cloudfront.net/static/img/logo1.png"
                                /> */}
                                <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} ImageUrl={""} />
                              </>
                              :
                              <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={VerificationMethod} />
                            }

                          </td>
                        </tr>
                        {VerificationMethod == "Manual" &&
                          <tr>
                            <td>Verification Image : </td>
                            <td>
                              <div className="col-12">
                                {VerificationStatus == "Unverified" &&

                                  <ImageUpload
                                    ImageUrl={VerificationID}
                                    setImageUrl={setVerificationID}
                                  />


                                }
                                {VerificationID == "" &&
                                  "Attach slip"}
                              </div>
                            </td>
                          </tr>
                        }
                        {VerificationStatus !== "Unverified" &&
                          <tr>
                            <td>Verification ID : </td>
                            <td>
                              <>
                                {VerificationID.includes("https://") ?
                                  <img src={VerificationID} height={"150px"} />
                                  :
                                  VerificationID
                                }
                              </>
                            </td>
                          </tr>
                        }
                        {VerificationStatus == "Unverified" &&
                          <tr>
                            <td colSpan={2}>
                              <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                {(VerificationID !== "" || VerificationMethod !== "Manual") &&
                                  <div className="sign-in-button-12 col-4"
                                    onClick={() => {
                                      var data;
                                      const date = new Date()
                                      data = {
                                        "title": `${Login.userID}-${item.id}`,
                                        "status": "publish",
                                        // "author": `${Login.userID}`,
                                        meta: {
                                          "batch_name_order": item.meta.batch_name,
                                          "batch_id_order": `${item.id}`,
                                          "student_id_order": `${Login.userID}`,
                                          "student_email_order": Login.userEmail,
                                          "student_phone_order": `${Login.userPhoneNumber}`,
                                          "student_username_order": `${Login.userName}`,
                                          "paid_amount": "0",
                                          "amount_to_pay": `${ExamPrice}`,
                                          "verification_status": "Unverified",
                                          "verification_method": VerificationMethod,
                                          "verification_date": `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                                          "verification_id": `${VerificationID}`,
                                        }
                                      }
                                      if (Data.order_data.length == 0)
                                        BuyBatchHandle(data)
                                      else {
                                        PayBatchHandle(data)
                                      }
                                    }}
                                  >
                                    Continue
                                    {VerificationMethod == "Esewa" && " with Esewa"}
                                    {VerificationMethod == "Khalti" && " with Khalti"}
                                  </div>
                                }
                              </div>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2 col-1"></div>
                <div className="col-sm-8 col-10 form-view m-3">
                  Exam List
                  <section>
                    {/* {JSON.stringify(item.meta.batch_exam_list)} */}
                    {JSON.parse(item.meta.batch_exam_list).map((item, index) => (
                      <div key={index}>
                        {index + 1} {item.name}
                      </div>
                    ))}
                  </section>
                </div>
              </div>

            </div>
          }
        </>
        :
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {/* <form ref={myform} action="https://esewa.com.np/epay/main" method="POST"> */}
        <form ref={myform} action="https://uat.esewa.com.np/epay/main" method="POST">
        <input value={ExamPrice} name="tAmt" type="hidden" />
        <input value={ExamPrice} name="amt" type="hidden" />
        <input value="0" name="txAmt" type="hidden" />
        <input value="0" name="psc" type="hidden" />
        <input value="0" name="pdc" type="hidden" />
        <input value="EPAYTEST" name="scd" type="hidden" />
        {/* <input value={esewaID} name="scd" type="hidden" /> */}
        <input value={pid} name="pid" type="hidden" />
        {/* <input value={`http://localhost:3000/batch_payment/${OrderID}?batch=${location.pathname.slice(slugs.batch_payment.length + 1)}`} type="hidden" name="su" /> */}
        <input value={`${adminURL}batch_payment/${OrderID}?batch=${location.pathname.slice(slugs.batch_payment.length + 1)}`} type="hidden" name="su" />
        {/* <input value={`http://localhost:3000${location.pathname}`} type="hidden" name="fu" /> */}
        <input value={`${adminURL}${location.pathname}`} type="hidden" name="fu" />
      </form>

    </div>
  )
}